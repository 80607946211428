import { useLoaderData } from 'react-router-dom';
import { formatCompositeField } from '../../util/formatCompositeField';
import { ConditionalBlockComponent } from '../../components/ConditionalBlockComponent/ConditionalBlockComponent';
import type { JcrNode } from '@magnolia/react-editor';
import {
	getMagnoliaBaseDamUrl,
	getMagnoliaBaseUrl,
} from '../../util/getMagnoliaBaseUrl';
import { SeoMeta } from '../../components/SeoMeta/SeoMeta';
import { ErrorPage } from '../ErrorPage';
import { StoryDetailPageHero } from './StoryDetailPageHero/StoryDetailPageHero';
import type { StoryDetailPageProps } from './types';

const StoryDetailPage = () => {
	const story = useLoaderData();
	const {
		title,
		lead,
		pageHeroButtons,
		blocks,
		featuredImage,
		metaCanonicalUrl,
		metaDescription,
		metaNoIndex,
		metaWindowTitle,
		schemaOrg,
		showOnSite,
	} = story as StoryDetailPageProps;
	const showDetailPage = showOnSite.includes('aptura');
	if (!showDetailPage) {
		return <ErrorPage />;
	}

	const featuredImageSrc = `${getMagnoliaBaseDamUrl()}${
		featuredImage['@link']
	}`;

	const blockData = formatCompositeField(
		blocks as JcrNode & Record<string, unknown>
	);

	const blockElements = blockData.map((block, i) => {
		const blockType = block['mgnl:type'];
		return (
			<div key={i}>
				<ConditionalBlockComponent blockType={blockType} blockData={block} />
			</div>
		);
	});

	return (
		<div>
			<SeoMeta
				windowTitle={metaWindowTitle}
				description={metaDescription}
				canonicalUrl={metaCanonicalUrl}
				noIndex={metaNoIndex}
				schemaOrg={schemaOrg}
				ogImageAsset={featuredImageSrc}
			/>
			<StoryDetailPageHero
				title={title}
				lead={lead}
				featuredImage={featuredImage}
				pageHeroButtons={pageHeroButtons}
			/>
			<div>{blockElements}</div>
		</div>
	);
};

const resourceDetailLoader = async ({
	params,
}: {
	params: {
		storyId?: string;
	};
}) => {
	const { storyId } = params;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const res = await fetch(
		`${magnoliaBaseUrl}${process.env.MGNL_API_STORIES}/resources/${storyId}`
	);
	if (res.status === 404) {
		throw new Response('Not Found', { status: 404 });
	}
	return res.json();
};

const insightDetailLoader = async ({
	params,
}: {
	params: {
		storyId?: string;
	};
}) => {
	const { storyId } = params;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const res = await fetch(
		`${magnoliaBaseUrl}${process.env.MGNL_API_STORIES}/insights/${storyId}`
	);
	if (res.status === 404) {
		throw new Response('Not Found', { status: 404 });
	}
	return res.json();
};

const legalDetailLoader = async ({
	params,
}: {
	params: {
		storyId?: string;
	};
}) => {
	const { storyId } = params;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const res = await fetch(
		`${magnoliaBaseUrl}${process.env.MGNL_API_STORIES}/legal/${storyId}`
	);
	if (res.status === 404) {
		throw new Response('Not Found', { status: 404 });
	}
	return res.json();
};

export {
	StoryDetailPage,
	resourceDetailLoader,
	insightDetailLoader,
	legalDetailLoader,
};
