import { Text } from '@dsx/react';
import { Footer } from '../Footer/Footer';
import styles from './FooterAptura.module.css';
import type { FooterLink, FooterLinkBlock, SocialLink } from '../Footer';
import logoSVG from './../../ds_logo.png';

const MenuVariantPaths = {
	CONNECT: '/connect',
	SERVICE_PROVIDER: '/service-provider',
};

import {
	DsxLinkedin,
	DsxTwitter,
	DsxYoutube,
	DsxFacebook,
} from '@dsx-react/icon';
import { useLocation } from 'react-router-dom';

const linkBlocks: FooterLinkBlock[] = [
	{
		category: '',
		links: [
			{
				text: 'Platform',
				url: '/technology/building-management-platform/',
				openInNewTab: false,
			},
			{
				text: 'Services',
				url: '/services/',
				openInNewTab: false,
			},
			{
				text: 'Solutions',
				url: '/solutions/',
				openInNewTab: false,
			},
			{
				text: 'TELS Summit',
				url: '/tels-summit/',
				openInNewTab: false,
			},
		],
	},
	{
		category: '',
		links: [
			{
				text: 'Resources',
				url: '/resources/',
				openInNewTab: false,
			},
			{
				text: 'Insights',
				url: '/insights/',
				openInNewTab: false,
			},
			{
				text: 'Sign in',
				url: 'https://www.tels.net/TELSLogin/signin.aspx',
				openInNewTab: true,
			},
		],
	},
];

const policyLinks: FooterLink[] = [
	{
		text: 'Website Requirements',
		url: '/legal/website-requirements/',
		openInNewTab: false,
	},
	{
		text: 'Terms of Use',
		url: '/legal/terms-of-use',
		openInNewTab: false,
	},
	{
		text: 'Privacy Policy',
		url: '/legal/privacy-policy',
		openInNewTab: false,
	},
	{
		text: 'Cookie Policy',
		url: '/legal/cookie-policy',
		openInNewTab: false,
	},
	{
		text: 'California Collection Notice',
		url: '/legal/privacy-policy#california-collection-notice',
		openInNewTab: false,
	},
];

const socialLinks: SocialLink[] = [
	{
		url: 'https://www.facebook.com/directsupply',
		openInNewTab: true,
		className: 'icon-facebook',
		title: 'Facebook',
		iconType: DsxFacebook,
	},
	{
		url: 'https://twitter.com/_DirectSupply',
		className: 'icon-twitter',
		openInNewTab: true,
		title: 'X',
		iconType: DsxTwitter,
	},
	{
		url: 'http://www.linkedin.com/company/direct-supply',
		className: 'icon-linkedin',
		title: 'LinkedIn',
		openInNewTab: true,
		iconType: DsxLinkedin,
	},
	{
		url: 'http://www.youtube.com/c/directsupply',
		className: 'icon-youtube',
		title: 'YouTube',
		openInNewTab: true,
		iconType: DsxYoutube,
	},
];

const renderContactContent = () => (
	<span>
		<Text variant="body-small">
			Need help or have a question?
			<br />
			<a href="/contact/" className={styles['contact-link']}>
				<Text variant="body-small" color="color-blue-500" element="span">
					Contact us
				</Text>
			</a>
			.
		</Text>
	</span>
);

const renderLogo = () => (
	<img src={logoSVG} alt="DS Aptura Logo" className={styles['logo']} />
);

const isPathMatch = (pathName: string, value: string) => {
	return pathName.startsWith(value);
};

const FooterAptura = () => {
	const currentYear = new Date().getFullYear();
	const copyright = `© 2009 - ${currentYear} Direct Supply, Inc. All rights reserved.`;
	const contact = renderContactContent();
	const logo = renderLogo();

	const { pathname } = useLocation();

	const socialLinksFiltered =
		isPathMatch(pathname, MenuVariantPaths.CONNECT) ||
		isPathMatch(pathname, MenuVariantPaths.SERVICE_PROVIDER)
			? []
			: socialLinks;

	const linkBlocksFiltered =
		isPathMatch(pathname, MenuVariantPaths.CONNECT) ||
		isPathMatch(pathname, MenuVariantPaths.SERVICE_PROVIDER)
			? []
			: linkBlocks;

	return (
		<Footer
			contact={contact}
			copyright={copyright}
			linkBlocks={linkBlocksFiltered}
			logo={logo}
			policyLinks={policyLinks}
			socialLinks={socialLinksFiltered}
		/>
	);
};

export { FooterAptura };
